<template>
          <div v-for="stock in stocks" :key="stock.idstocks" class="chartbox">

           <div v-if="(aktuellerUser == 'joe' && stock.user_iduser.iduser == 1 )||(aktuellerUser != 'joe')">

            <v-toolbar
                    class="hidden-md-and-down"
                    v-intersect="{
                    //handler: onIntersect,
                    options: {
                       threshold: [0, 0.5, 1.0]
                       }
                    }"
                  density="compact"
                >
              <v-toolbar-title>{{stock.stocks_name}}</v-toolbar-title>

              <v-spacer></v-spacer>

            </v-toolbar>

              <v-card
                class="mx-auto"
                :title="getChartTitle(stock.stocks_name,stock.stocks_Menge,stock.stock_Aktuellerkurs)"
                v-bind:color='getCardcolor(stock.stocks_Menge,stock.stocks_name)'
                :subtitle="'symbol_' + stock.idstocks + stock.stocks_symbol_typ + ' 25% Verkaufskurs ' + Math.floor(stock.stock_25ProzentVerkauflimit) + ' €'"
                :prepend-avatar='"userdir/stocksymbols/symbol_"+ stock.idstocks +"." + stock.stocks_symbol_typ'
              >

              <template v-slot:append>
                  <v-avatar color="teal lighten-3" v-if="stock.stock_delta1_prozent >= 0">
                    <v-icon icon="mdi-trending-up"></v-icon>
                  </v-avatar>
                  <v-avatar color="red lighten-3" v-if="stock.stock_delta1_prozent < 0">
                    <v-icon icon="mdi-trending-down"></v-icon>
                  </v-avatar>
                </template>


                    <v-item-group multiple>

                         <template v-if="stock.stock_delta1_prozent >= 0">
                            <v-chip variant="flat" color="teal lighten-3"
                              class="ma-2"
                              append-icon="mdi-trending-up"
                              size = "x-small"
                            >
                              Tag: {{stock.stock_delta1_prozent}} %
                            </v-chip>
                        </template>
                        <template v-else>
                            <v-chip variant="flat" color="red lighten-3"
                              class="ma-2"
                              append-icon="mdi-trending-down"
                              size = "x-small"
                            >
                              Tag: {{stock.stock_delta1_prozent}} %
                            </v-chip>
                        </template>

                         <template v-if="stock.stock_delta5_prozent >= 0">
                            <v-chip variant="flat" color="teal lighten-3"
                              class="ma-2"
                              append-icon="mdi-trending-up"
                              size = "x-small"
                            >
                              Woche: {{stock.stock_delta5_prozent}} %
                            </v-chip>
                        </template>
                        <template v-else>
                            <v-chip variant="flat" color="red lighten-3"
                              class="ma-2"
                              append-icon="mdi-trending-down"
                              size = "x-small"
                            >
                              Woche: {{stock.stock_delta5_prozent}} %
                            </v-chip>
                        </template>

                         <template v-if="stock.stock_delta20_prozent >= 0">
                            <v-chip variant="flat" color="teal lighten-3"
                              class="ma-2"
                              append-icon="mdi-trending-up"
                              size = "x-small"
                            >
                              Mon: {{stock.stock_delta20_prozent}} %
                            </v-chip>
                        </template>
                        <template v-else>
                            <v-chip variant="flat" color="red lighten-3"
                              class="ma-2"
                              append-icon="mdi-trending-down"
                              size = "x-small"
                            >
                              Monat: {{stock.stock_delta20_prozent}} %
                            </v-chip>
                        </template>

                    </v-item-group>
                </v-card>

                <v-card
                >


                  <div style="max-width:1000px">
                    <canvas  v-bind:id="'stockchart_'+stock.idstocks.toString()"></canvas>
                  </div>


                </v-card>

           </div>
          </div>



</template>

<script>
import { mapGetters, mapActions } from 'vuex';


import { Chart, registerables  } from "chart.js";
import chartjsPluginAnnotation from "chartjs-plugin-annotation";
Chart.register(...registerables);
Chart.register(chartjsPluginAnnotation)

export default {
  name: 'ChartScreen',
   data () {
    return {

          prozent: false,
          counter: 0,
          ausgewaehlteStockIDs: [],
          lineChartData : {
          type: 'line',
          aktuellerUser: '',
          data: {
            labels: ["January", "February", "March", "April", "May", "June", "July"],
            datasets: [
              { // one line graph
                label: "Kurs 1(€)",
                data: [0, 0, 1, 2, 67, 62, 27, 14],
                fill:false,
                pointRadius: 0,
                borderColor: 'Orange',
                borderWidth: 2,
                yAxisID: "y-axis-0",
              },
              { // one line graph
                label: "Kurs 2(€)",
                data: [0, 30, 41, 22, 67, 162, 27, 14],
                fill:false,
                pointRadius: 0,
                borderColor: 'Green',
                borderWidth: 2,
                yAxisID: "y-axis-0",
              },
              { // another line graph
                label: "Trend (%)",
                data: [4.8, 12.1, 12.7, 6.7, 139.8, 116.4, 50.7, 49.2],
                fill:true,
                borderColor: 'Blue',
                pointRadius: 0,
                borderWidth: 1,
                yAxisID: "y-axis-1",
              }
            ]
          },
          options: {
            responsive: true,
            lineTension: 0.2,
            plugins: {
                annotation: {
                  annotations: {
                      type: 'line',
                      mode: 'horizontal',
                      value: 6700,
                      borderColor: 'orange',
                      borderWidth: 20,
                      //scaleID: 'y-axis-0',
                    }
                }
              },
//            scales: {
//                yAxes: [{
//                          stacked: false, //damit werden die data lines adiiert !!! das will ich nicht
//                          position: "right",
//                          id: "y-axis-0",
//                          display: true,
//                              ticks: {
//                                 beginAtZero: false,
//                                 max: 2000,
//                                 min: 1000,
//                                 stepSize: 100
//                                },
//                        }, {
//                          stacked: false,
//                          position: "right",
//                          id: "y-axis-1",
//                          display: true,
//                        }],
//                  }//scales
          }//options
        },
    }
  },
      watch: {
      model (val, prev) {
        if (val.length === prev.length) return

        this.model = val.map(v => {
          if (typeof v === 'string') {
            v = {
              text: v,
              color: this.colors[this.nonce - 1],
            }

            this.items.push(v)

            this.nonce++
          }

          return v
        })
      },
    },
  created: function() {
     console.log('-->>Bin in Chart created')
     //zuerst nach stocks_name sortieren

     var obj = this

     obj.$store.dispatch('getStocks').then(()=>{
        //console.log("--Got some data--")
              obj.stocks.sort((a, b) => (a.stocks_name > b.stocks_name) ? 1 : -1)
              console.log('##>>Sorted StockList',obj.stocks)

        }, error => {
            console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
        })


  },
  computed: {
    ...mapGetters({ stocks: 'stateStocks', users: 'stateUser',
     stockhistories: 'stateStockhistories', stocksignals: 'stateStocksignals', getSettingBySettingkey: 'getSettingBySettingkey'}),
  },
  mounted: function () {
      //console.log('>>##>>>calendar mounted..',this.stockhistories,this.stocksignals)

      //erst mal alle historien abrufen, das laeuft asynchron
      this.counter = 0
      this.aktuellerUser = this.$store.getters.stateUser.user_username

      this.makeOptionsListFromStockList()
      // wenn alle gelesen sind dann erst

      //woher weiss ich dass alle asynchorn gelesen sind

      //den draw in den canvas aufrufen





      },
  methods: {
    ...mapActions(['createStock']),
    onChangeCombo3(entry){
        console.log('So this is your favorite number: ',entry);
    },
      onIntersect (entries, observer) {
        // More information about these options
        // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
        //console.log('intersection',entries[0])
        //console.log('target',entries[0].target)
        //console.log('target',entries[0].target.innerHTML )
        //console.log('target',entries[0].target.children[0].children[2].innerText )
        console.log('observer',observer)
        this.isIntersecting = entries[0].isIntersecting
        this.IntersectingText = entries[0].target.children[0].children[2].innerText
      },
      getChartTitle (stocks_name, stocks_Menge,stock_Aktuellerkurs) {
        var ChartTitle = stocks_name
        if(stocks_Menge > 0){
            var Gesamtwert = Math.floor(stocks_Menge * stock_Aktuellerkurs)
            Math.round(Gesamtwert)
            // Format the price above to USD using the locale, style, and currency.
            let Gesamtwertformatter = new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
                trailingZeroDisplay: 'stripIfInteger',
            });
            ChartTitle = stocks_name + '         Gesamtwert: ' + Gesamtwertformatter.format(Gesamtwert)
            }

        return ChartTitle
      },
    async getSignaleAsync(ausgewaehlteStockIDs,stockhistoriesOptions) {
              //console.log('Ready getSignaleAsync stockids',ausgewaehlteStockIDs);

              await this.$store.dispatch('getSignale',ausgewaehlteStockIDs)
              //verikale Linien nur auf groessen Displays
              if (Math.min(window.screen.width, window.screen.height) > 768) {
                stockhistoriesOptions.options.plugins.annotation.annotations = this.stocksignals
                }

              return stockhistoriesOptions
    },

    async makeOptionsListFromStockList()
    {
      //console.log('makeOptioensListFromStockList',this.stocks)
      //loop stock
      // all options as list/array
      let stockhistoriesOptions = Object.assign({}, this.lineChartData) // Copies user into clone

      var obj = this


      //evtl unnotige raussschmeissen user_iduser = 0 oder im BAckend?

      console.log('User ist eingeloggt',this.aktuellerUser);

      for(var n in obj.stocks){
         var datasetOptionsRow = {}
         datasetOptionsRow['stockid'] = obj.stocks[n].idstocks


        //stockhistoriesOptions = this.makeList(obj.stockhistories,stockhistoriesOptions)

         //console.log('##>>makeOptioensListFromStockList for stock of user',obj.stocks[n].user_iduser.iduser)
         if ((this.aktuellerUser == 'joe' && obj.stocks[n].user_iduser.iduser == 1 ) ||(this.aktuellerUser != 'joe'))
         {

             //if ((obj.stocks[n].idstocks == 19) || (obj.stocks[n].idstocks === 39))  //nur zum test 49 schmiert ab
             if (obj.stocks[n].idstocks !== 1042)  //Prod
              {//nur zum >>testen
                     //console.log('Get History for stock ID',this.stocks[n].idstocks)
                      var ausgewaehlteStockIDs = [] //payload
                      ausgewaehlteStockIDs.push(obj.stocks[n].idstocks);
                      //console.log('##Ausgewählte Stock for dispatch:',obj.stocks[n].idstocks)

                     if (obj.stocks[n].idstocks === 39){
                        console.log('Problem mit der ersten ID??:',obj.counter, obj.stocks[n].idstocks)
                     }

                     obj.counter += 1


                      await obj.$store.dispatch('getStockhistories',ausgewaehlteStockIDs)
                      //console.log("--Got some data nach dispatch--",ausgewaehlteStockIDs,response)
                      //console.log("---->>nach dispatch- DATA--",response)
                      obj.stockhistories.forEach((x)=>{
                            //console.log('-+++',x)
                            delete x["stocks_idstocks"];
                        })

                      if (obj.stockhistories.length>0){
                          stockhistoriesOptions = obj.makeList(obj.stockhistories,stockhistoriesOptions)
                          // bis hier tut es

                          stockhistoriesOptions = await obj.getSignaleAsync(ausgewaehlteStockIDs,stockhistoriesOptions)

                          obj.drawChart2(stockhistoriesOptions,obj.stocks[n].idstocks)
                      }


             }//nur zum test
         }//bei Joe nur die von joe anzeigen
          }

             if (obj.counter == 1)  //Prod
              {
                //afterburner fuer ersten Stock warum auch immer
                    ausgewaehlteStockIDs = [] //payload

                    for (var i = 0; i < this.stocks.length; i++) {

                            console.log('>>match>>',obj.stocks[i].stocks_name,obj.stocks[i].user_iduser.user_username);
                            if ((obj.stocks[i].stocks_name == '0_DepotKursAktuell') &&  (obj.stocks[i].user_iduser.user_username == 'joe')){
                                console.log('0_DepotKursAktuell ID:',obj.stocks[i].idstocks)
                                ausgewaehlteStockIDs.push(obj.stocks[i].idstocks);
                                break
                             }

                    }

                      //obj.stocks[c].stocks_name == '0_DepotKursAktuell' && obj.stocks[c].user_iduser.user_username == 'joe'
                      //ausgewaehlteStockIDs.push(obj.stocks[0].idstocks);
                      //console.log('##Ausgewählte Stock for dispatch:',obj.stocks[n].idstocks)



                      await obj.$store.dispatch('getStockhistories',ausgewaehlteStockIDs)
                      //console.log("--Got some data nach dispatch--",ausgewaehlteStockIDs,response)
                      //console.log("---->>nach dispatch- DATA--",response)
                      obj.stockhistories.forEach((x)=>{
                            //console.log('-+++',x)
                            delete x["stocks_idstocks"];
                        })

                      if (obj.stockhistories.length>0){
                          stockhistoriesOptions = obj.makeList(obj.stockhistories,stockhistoriesOptions)
                          // bis hier tut es

                          stockhistoriesOptions = await obj.getSignaleAsync(ausgewaehlteStockIDs,stockhistoriesOptions)

                          obj.drawChart2(stockhistoriesOptions,obj.stocks[n].idstocks)
                          }
                  }

    },

    getCardcolor(Menge,stocks_name){
        //console.log('stocks_name:',stocks_name)
        if (stocks_name.substring(0,2) == '0_') return '#7199f0'
        else if (Menge < 0.00001) return '#8a8f99'
        return '#7199f0'  //Als Hex code da der Text nicht tut...
    },


    drawChart2(lineChartData,idstocks){

        let canvasID = 'stockchart_'+idstocks.toString()

        // JS - Destroy exiting Chart Instance to reuse <canvas> element
        let chartStatus = Chart.getChart(canvasID); // <canvas> id
        if (chartStatus != undefined) {
          chartStatus.destroy();
        }


        //-- End of chart destroy


          //draw chart
          const ctx = document.getElementById(canvasID);
            //console.log('chartdata:',lineChartData)

            var myChart = new Chart(ctx, { // eslint-disable-line
              type: lineChartData.type,
              data: lineChartData.data,
              options: lineChartData.options,
          });
          //console.log('-->Manu DrawCahrt2 aha',myChart)


    },



    DrawOneYear(){
        console.log('Buttonon 1 year pressed ');
        console.log('selectedStocks.',this.selectedStocks)

        //warum muss ich 2 mal druecken damit es aktualsiert wird? vielleicht erst auf den return wert warten

        var ausgewaehlteStockIDs = [] //payload

        this.selectedStocks.forEach((x, i)=>{
            console.log('-+++',i,x.idstocks)
            ausgewaehlteStockIDs.push(x.idstocks);
        })


        //this.$store.dispatch('getStockhistories',ausgewaehlteStockIDs)

		this.$store.dispatch('getStockhistories',ausgewaehlteStockIDs).then(response => {
            console.log("Got some data, now lets show something in this component",response)
            this.listGlobal = this.stockhistories
            if (this.listGlobal.length>0){
                this.makeList(this.listGlobal)
                this.drawChart()
            }
        }, error => {
            console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
        })

        //}  //hier ist der AUfruf wie mit Parameter aus settings db?



        //this.makeList(this.listGlobal)
    },
        drawChart() {

        // JS - Destroy exiting Chart Instance to reuse <canvas> element
        let chartStatus = Chart.getChart("line-chart"); // <canvas> id
        if (chartStatus != undefined) {
          chartStatus.destroy();
        }
        //-- End of chart destroy

      var obj = this
      var neueLineChartDaten = obj.lineChartData

          //draw chart
          const ctx = document.getElementById('line-chart');
            //  console.log('chartdata:',chartData.data)

            var myChart = new Chart(ctx, { // eslint-disable-line
              type: neueLineChartDaten.type,
              data: neueLineChartDaten.data,
              options: neueLineChartDaten.options,
          });
          console.log('aha',myChart)
    },
    //macht aus Liste alle Stocks ein Daten Set aufbereitet fuer Chart.js
    makeList(filteredListGlobal,stockhistoriesOptions){

        var obj = this

              stockhistoriesOptions.data.labels = []

              var counter = 0
              //var linecolors = ['Orange','Green','Blue','White','Purple','Grey','Red','Yellow']


                stockhistoriesOptions.data.datasets =[]

                // Looping through arrays created from Object.keys
                const keys = Object.keys(filteredListGlobal[0])
                for (const key of keys) {
                  //console.log('LOAD>>>>>>>',key)
                  //if(key === 'datum')
                  //  console.log('---make List-----nada')
                  if (key === 'trend'){
                    console.log('-make List--TREND')
                     var datasetDynRow = {}
                        datasetDynRow['label'] = key
                        datasetDynRow['data'] = [1,2,4,7,8]
                        datasetDynRow['fill'] = false
                        datasetDynRow['pointRadius'] = 0
                        datasetDynRow['borderDash'] = [10, 10]
                        datasetDynRow['borderColor'] = 'Yellow'
                        datasetDynRow['borderWidth'] = 2
                        //datasetDynRow['yAxisID'] = 'y-axis-0'
                        //console.log('ee>>>>>',datasetDynRow)

                         //nachdem die Column bekannt ist das entsprechende dataset einlesen
                         datasetDynRow['data'] = []
                               for(var x in filteredListGlobal){
                                  //arr.push(parsed[x]);
                                  //
                                  //console.log("########Wert:",liste[x][key])
                                  datasetDynRow['data'].push(filteredListGlobal[x][key])
                                  if(counter === 0){//nur einmal fuer labels
                                    stockhistoriesOptions.data.labels.push(filteredListGlobal[x]['datum'])
                                    }

                              }
                         stockhistoriesOptions.data.datasets.push(datasetDynRow)

                 }
                 else{ //Kurswert und kein Trend
                       datasetDynRow = {}
                        datasetDynRow['label'] = key
                        datasetDynRow['data'] = [1,2,4,7,8]
                        datasetDynRow['fill'] = true
                        datasetDynRow['pointRadius'] = 0
                        if (key === 'MA20'){
                            datasetDynRow['borderColor'] = '#a0b58b'
                            datasetDynRow['borderDash'] = [10, 10]
                            datasetDynRow['borderWidth'] = 1
                            }
                        else if (key === 'MA50'){
                            datasetDynRow['borderColor'] = '#4366d1'
                            datasetDynRow['borderDash'] = [10, 10]
                            datasetDynRow['borderWidth'] = 2
                            }
                        else if (key === 'MA200'){
                            datasetDynRow['borderColor'] = '#d1437c'
                            datasetDynRow['borderDash'] = [10, 10]
                            datasetDynRow['borderWidth'] = 2
                            }
                        else{
                            datasetDynRow['borderColor'] = '#e4f2f2'
                            datasetDynRow['borderWidth'] = 2
                            }
                        datasetDynRow['fill'] = false
                        //datasetDynRow['yAxisID'] = 'y-axis-0'
                        //console.log('ee>>>>>',datasetDynRow)

                         //nachdem die Column bekannt ist das entsprechende dataset einlesen
                         datasetDynRow['data'] = []
                               for(x in filteredListGlobal){
                               //for(var x in [0,1,2,3]){
                                  //arr.push(parsed[x]);
                                  //
                                  //console.log("########Wert:",liste[x][key])
                                  if(obj.prozent === true){ //Prozentuale Werte

                                  var prozentualerWert = (filteredListGlobal[x][key]/filteredListGlobal[0][key])*100
                                  //console.log("#####PROZENT###Wert:",key, prozentualerWert)

                                  datasetDynRow['data'].push(prozentualerWert)
                                  //datasetDynRow['data'].push(200)

                                  }
                                  else{ //absolute Werte
                                  datasetDynRow['data'].push(filteredListGlobal[x][key])
                                  //console.log("#####ABSOLUT###Wert:",liste[x][key])

                                  }


                                  if(counter === 0){//nur einmal fuer labels
                                    stockhistoriesOptions.data.labels.push(filteredListGlobal[x]['datum'])
                                    //console.log('--Datum:',filteredListGlobal[x]['datum'])
                                    }

                              }

                         //console.log("#####datasetDynRow###WertList:",key, datasetDynRow)
                         stockhistoriesOptions.data.datasets.push(datasetDynRow)
                         counter +=1

                  }
              }

              //jetzt fuer die annotations noch die Bezeichnung via dummy Daten in die Legende setzen

              const green = '#748f6a'//#green darken-1
              const orange = '#9e6b3c'//#orange darken-1
              datasetDynRow = {}
                        datasetDynRow['label'] = '25% VERKAUF Signal'
                        datasetDynRow['data'] = []
                        datasetDynRow['type'] = 'line'
                        datasetDynRow['borderColor'] = orange
                        datasetDynRow['borderWidth'] = 2
                        datasetDynRow['borderDash'] = [5,2]

              stockhistoriesOptions.data.datasets.push(datasetDynRow)

              datasetDynRow = {}
                        datasetDynRow['label'] = '25% KAUF Signal'
                        datasetDynRow['data'] = []
                        datasetDynRow['type'] = 'line'
                        datasetDynRow['borderColor'] = green
                        datasetDynRow['borderWidth'] = 2
                        datasetDynRow['borderDash'] = [5,2]

              stockhistoriesOptions.data.datasets.push(datasetDynRow)

              datasetDynRow = {}
                        datasetDynRow['label'] = 'MA20 > MA50 VERKAUF Signal'
                        datasetDynRow['data'] = []
                        datasetDynRow['type'] = 'line'
                        datasetDynRow['borderColor'] = orange
                        datasetDynRow['borderWidth'] = 1

              stockhistoriesOptions.data.datasets.push(datasetDynRow)

              datasetDynRow = {}
                        datasetDynRow['label'] = 'MA200xMA50 KAUF Signal'
                        datasetDynRow['data'] = []
                        datasetDynRow['type'] = 'line'
                        datasetDynRow['borderColor'] = green
                        datasetDynRow['borderWidth'] = 1

              stockhistoriesOptions.data.datasets.push(datasetDynRow)



              //check if we are on a mobile browser

             //alert(answer ? 'It is a mobile device' : 'It is not a mobile device');
             if (Math.min(window.screen.width, window.screen.height) < 768) {

                  //y-Achse rechts setzen und x-Achsen labels ausblenden auf mobile
                      stockhistoriesOptions.options.scales = {
                          y: {
                            position: 'right',
                            id: 'y-axis-0',
                            //min: 10,
                            //max: 500000,

                          },
                              // to remove the labels
                        x: {
                              ticks: {
                                display: false,
                              },

                              // to remove the x-axis grid
                              grid: {
                                drawBorder: false,
                                display: false,
                              },
                         },
                      }

                  } else {
                   //auf PC nur y-Achse rechts setzen
                  stockhistoriesOptions.options.scales = {
                      y: {
                        position: 'right',
                        id: 'y-axis-0',
                        //min: 10,
                        //max: 500000,

                      },

                    }
                  }

              // vertikale Linie setzen als bar
//                let datatset_vertikaleLinien = {
//                    type: 'bar',
//                    backgroundColor: linecolors[1],
//                    data: [
//                      {x: '2021-01-31T00:00:00', y: 14400}
//                    ],
//                    maxBarThickness: 20,
//                  }
//
//              stockhistoriesOptions.data.datasets.push(datatset_vertikaleLinien)

              // vertikale linien als annotations

              stockhistoriesOptions.options.plugins = {
                                  legend: {
                                          display: true,
                                          position: 'chartArea'
                                  },
                                  annotation: {
//                                      annotations: [
//                                         {
//                                          type: 'line',
//                                          mode: 'vertical',
//                                          scaleID: 'x',
//                                          value: '2020-01-31T00:00:00',
//                                          borderColor: 'yellow',
//                                          borderWidth: 5,
//                                          borderDash: [5,5],
//                                         },
//                                         {
//                                          type: 'line',
//                                          mode: 'vertical',
//                                          scaleID: 'x',
//                                          value: '2018-04-30T00:00:00',
//                                          borderColor: 'yellow',
//                                          borderWidth: 5,
//                                          borderDash: [5,5],
//                                         },
//                                      ]
                                },
                          }

//              stockhistoriesOptions.options.plugins = {
//                    annotation: {
//                                  annotations: [
//                                     {
//                                      type: 'line',
//                                      mode: 'horizontal',
//                                      //scaleID: 'y-axis-0',
//                                      value: 4500,
//                                      borderColor: 'orange',
//                                      borderWidth: 4,
//                                      borderDash: [5,5],
//                                      scaleID: 'y',
//                                     },
//
//                                  ]
//                                },
//                          }

              return stockhistoriesOptions
      },
  },
};
</script>

<style>

.chartbox {
  min-width: 100px;
}
</style>