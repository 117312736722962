import axios from 'axios';

const state = {
  settings: null,
  setting: null
};

const getters = {
  stateSettings: state => state.settings,
  stateSetting: state => state.setting,

  getSettingBySettingkey: (state) => (settingskey) => {
    console.log('>>>>>>>>>>>>>>>>>>Bin in StoreSettingbykey',settingskey)
    return state.settings.find(settings => settings.settings_key === settingskey)
  }
};

const actions = {
  async createSetting({dispatch}, setting) {
    await axios.post('settings', setting);
    await dispatch('getSettings');
  },
  async getSettings({commit}) {
  console.log('>>>>>>>>>>>>>>>>#>Bin in getSettings')
    let {data} = await axios.get('settings');
    commit('setSettings', data);
  },
  async viewSetting({commit}, id) {
    console.log('setting.js: viewSetting:',id)

    let {data} = await axios.get(`setting/${id}`);
    commit('setSetting', data);
  },
  // eslint-disable-next-line no-empty-pattern
  async updateSetting({}, payload) {
   console.log('updateSetting:',payload)

    //console.log('updateSetting:',payload[1].idSettings)
    if (payload[1].idSettings> 0){
        await axios.patch('setting/' + payload[1].idSettings.toString(), payload[0]);
        }
    else{
        await axios.patch('setting/0', payload[0]);
    }



  },
  // eslint-disable-next-line no-empty-pattern
  async deleteSetting({dispatch}, key) {
    await axios.delete(`setting/${key}`);
    await dispatch('getSettings');
  }
};

const mutations = {
  setSettings(state, settings){
    state.settings = settings;
  },
  setSetting(state, setting){
    state.setting = setting;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};