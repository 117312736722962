<template>


  <div>
    <section>

    <v-card
        color="white"
        >
          <div>
            <canvas id="bar2-chart"></canvas>
           </div>
    </v-card>

    <v-row>
        <v-select
          v-model="e7"
          label="Select Kategorien"
          item-title="Kategorie"
          item-value="dbname"
          :items="attribute"
          @update:modelValue="selection_changedValues(e7)"

          hint="Pick to compare"
          persistent-hint
          multiple
          return-object = True
        ></v-select>

        <v-text-field
          label="Offset"
          v-model="offset"
        ></v-text-field>


    </v-row>

        <v-btn color="secondary" @click="blaettern('zurueck')">r</v-btn>
        <v-btn color="primary" @click="filterDates('Woche')">7 T</v-btn>
        <v-btn color="primary" @click="filterDates('Monat')" >1 M</v-btn>
        <v-btn color="primary" @click="filterDates('Jahr')" >1 J</v-btn>
        <v-btn color="secondary" @click="blaettern('vorwaerts')">v</v-btn>

        <v-card
           color="white"
        >
          <div>
            <canvas id="line4TSS-chart"></canvas>
           </div>
        </v-card>

    <v-card
       color="white"
    >
      <div>
        <canvas id="line2-chart"></canvas>
       </div>
    </v-card>

        <v-card
           color="white"
        >
          <div>
            <canvas id="bar3HR-chart"></canvas>
           </div>
        </v-card>

      <v-data-table
        :headers="headers"
        :items="array_allActivities"
        density="compact"
        :items-per-page=10
        fixed-header=true
        height="540"
        @click:row="handleClickActivty"
        class="elevation-1 text-caption"
      >


        <template v-slot:item.aktivitaeten_startTimeGMT="{ item }">
          <div v-text="item.aktivitaeten_startTimeGMT.toLocaleDateString()"></div>
        </template>


        <template v-slot:item.aktivitaeten_distance="{ item }">
          <div v-text="Math.ceil((parseFloat(item.aktivitaeten_distance)/1000)*100)/100 + ' km'"></div>
        </template>

        <template v-slot:item.aktivitaeten_averageSpeed="{ item }">
          <div v-text="Math.ceil(item.aktivitaeten_averageSpeed * 3.6 *100)/100 + ' km/h'"></div>
        </template>

        <template v-slot:item.aktivitaeten_maxSpeed="{ item }">
          <div v-text="Math.ceil(item.aktivitaeten_maxSpeed *3.6 *100)/100+ ' km/h'"></div>
        </template>

        <template v-slot:item.aktivitaeten_movingaverageSpeed="{ item }">
          <div v-text="item.aktivitaeten_movingaverageSpeed  + ' km/h'"></div>
        </template>

        <template v-slot:item.aktivitaeten_duration="{ item }">
          <div v-text="Math.floor(item.aktivitaeten_duration / 60) + ':' + ('0' + Math.floor(item.aktivitaeten_duration % 60)).slice(-2) + ' Min'"></div>
        </template>

        <template v-slot:item.aktivitaten_activityId="{ item }">
          <div v-text="item.aktivitaten_activityId"></div>
        </template>

        <template v-slot:item.aktivitaten_elevationGain="{ item }">
          <div v-text="item.aktivitaten_elevationGain + ' m'"></div>
        </template>

        <template v-slot:item.aktivitaeten_maxElevation="{ item }">
          <span v-text="Math.floor(item.aktivitaeten_maxElevation / 100) + ' m'"></span>
        </template>

        <template v-slot:item.aktivitaeten_minElevation="{ item }">
          <span v-text="Math.floor(item.aktivitaeten_minElevation / 100) + ' m'"></span>

        </template>


      </v-data-table>




    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Chart, registerables  } from "chart.js";
Chart.register(...registerables);
import _ from 'lodash'

export default {
  name: 'ActivitiesScreen',
  data() {
    return {
      form: {
        title: '',
        content: '',
      },
      zeitraum: 'Monat',
      bar2Chart: null,
      array_allActivitiesJSON:[],
      e7:[{ Kategorie: 'Gesamtstrecke', dbname: 'aktivitaeten_distance' },{ Kategorie: 'GesamtstreckeIndoors', dbname: 'aktivitaeten_distanceIndoor' }], //Kategorien die summiert werden,
      e8_Durchschnitt:[{ Kategorie: 'DurchschnittsHR', dbname: 'aktivitaeten_averageHR' },{ Kategorie: 'Durchschnittsgeschwindigkeit', dbname: 'aktivitaeten_averageSpeed' }],
            e9_HR:[{ Kategorie: 'DurchschnittsHR_1', dbname: 'aktivitaeten_Zone1_secs' },{ Kategorie: 'DurchschnittsHR_2', dbname: 'aktivitaeten_Zone2_secs' },
        { Kategorie: 'DurchschnittsHR_3', dbname: 'aktivitaeten_Zone3_secs' },{ Kategorie: 'DurchschnittsHR_4', dbname: 'aktivitaeten_Zone4_secs' },{ Kategorie: 'DurchschnittsHR_5', dbname: 'aktivitaeten_Zone5_secs' }],
    attribute: [{ Kategorie: 'Kalorien', dbname: 'aktivitaeten_calories' },{ Kategorie: 'Gesamtstrecke', dbname: 'aktivitaeten_distance' },
    { Kategorie: 'Gesamthoehenmeter', dbname: 'aktivitaten_elevationGain' },
    { Kategorie: 'GesamtstreckeIndoors', dbname: 'aktivitaeten_distanceIndoor' },
    { Kategorie: 'Gesamdauer', dbname: 'aktivitaeten_duration' },
    { Kategorie: 'GesamtAnzahlAktivitaeten', dbname: 'aktivitaeten_AnzahlAktivitaeten' },
    ],
      Users:[],
      offset: 30,
      array_allActivities: [],
          bar2ChartData : {
                type: 'bar',
                data: {
                  labels: ["January", "February", "March", "April", "May", "June", "July"],
                  datasets: [
                    { // one line graph
                      label: "Ø Speed (km/h)",
                      data: [0, 0, 1, 2, 67, 62, 27, 14],
                      backgroundColor: 'rgba(75, 192, 192, 0.2)',
                      borderWidth: 2,
                      yAxisID: "y-axis-unstacked",
                    },
                    { // another line graph
                      label: "Ø Herzfreq.",
                      data: [4.8, 12.1, 12.7, 6.7, 139.8, 116.4, 50.7, 49.2],
                      backgroundColor: 'rgba(255, 99, 132, 0.2)',
                      borderWidth: 1,
                      yAxisID: "y-axis-unstacked",
                    }
                  ]
                },
                options: {
                  responsive: true,
                  lineTension: 1,

                }
              },
        line2ChartData : {
                type: 'bar',
                data: {
                  labels: ["January", "February", "March", "April", "May", "June", "July"],
                  datasets: [
                    { // one line graph
                      label: "Ø Speed (km/h)",
                      data: [0, 0, 1, 2, 67, 62, 27, 14],
                      backgroundColor: 'rgba(75, 192, 192, 0.2)',
                      yAxisID: "yaxisLinks",
                    },
                    { // another line graph
                      label: "Ø Herzfreq.",
                      data: [4.8, 12.1, 12.7, 6.7, 139.8, 116.4, 50.7, 49.2],
                      backgroundColor: 'rgba(255, 99, 132, 0.2)',
                      yAxisID: "yaxisRechts",
                    }
                  ]
                },
                options: {
                  responsive: true,
                  lineTension: 1,
                scales: {
                    yAxes: [{
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: "left",
                        id: "y-axis-1",
                      scaleLabel: {
                        display: true,
                        labelString: 'Ø Heartrate / Minute'
                      },
                    }, {
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: "right",
                        id: "y-axis-2",
                      scaleLabel: {
                        display: true,
                        labelString: 'Ø km / h'
                      },
                        // grid line settings
                        gridLines: {
                            drawOnChartArea: false, // only want the grid lines for one axis to show up
                        },
                    }],
                }
                }
              },
        line2Chart: null,
        line4TSSChartData : {
                type: 'bar',
                data: {
                  labels: ["January", "February", "March", "April", "May", "June", "July"],
                  datasets: [
                    { // one line graph
                      label: "Ø Speed (km/h)",
                      data: [0, 0, 1, 2, 67, 62, 27, 14],
                      backgroundColor: 'rgba(75, 192, 192, 0.2)',
                      yAxisID: "yaxisLinks",
                    },
                    { // another line graph
                      label: "Max FTP",
                      data: [4.8, 12.1, 12.7, 6.7, 139.8, 116.4, 50.7, 49.2],
                      backgroundColor: 'rgba(255, 99, 132, 0.2)',
                      fill:false,
                      yAxisID: "yaxisRechts",
                    }
                  ]
                },
                options: {
                  responsive: true,
                  lineTension: 1,
                  scales: {
                      yAxes: [{
                          stacked: false
                      }],

                        },


                }
              },
           line4TSSChart: null,
        e10_TSS_Durchschnitt:[{ Kategorie: 'DurchschnittsTSS', dbname: 'aktivitaeten_trainingStressScore' },{ Kategorie: 'DurchschnittsFTP', dbname: 'aktivitaeten_maxFtp' }],
                  bar3HRChartData : {
            type: 'bar',
                data: {
                  labels: ["January", "February", "March", "April", "May", "June", "July"],
                  datasets: [
                    { // one line graph
                      label: "Ø Speed (km/h)",
                      data: [0, 0, 1, 2, 67, 62, 27, 14],
                      backgroundColor: 'rgba(75, 192, 192, 0.2)',
                      borderWidth: 2
                    },
                    { // another line graph
                      label: "Ø Herzfreq.",
                      data: [4.8, 12.1, 12.7, 6.7, 139.8, 116.4, 50.7, 49.2],
                      backgroundColor: 'rgba(255, 99, 132, 0.2)',
                      borderWidth: 1
                    }
                  ]
                },
                options: {
                  responsive: true,
                  lineTension: 1,
                  scales: {
                      yAxes: [{
                          stacked: false,
                          ticks: {
                             beginAtZero: true
                           },
                      scaleLabel: {
                        display: true,
                        labelString: 'Ø Minuten'
                      },
                      }],

                        }
                }
              },
              bar3HRChart: null,
        lineChartData : {
                type: 'line',
                data: {
                  labels: ["January", "February", "March", "April", "May", "June", "July"],
                  datasets: [
                    { // one line graph
                      label: "Ø Speed (km/h)",
                      data: [0, 0, 1, 2, 67, 62, 27, 14],
                      backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    },
                    { // another line graph
                      label: "Ø Herzfreq.",
                      data: [4.8, 12.1, 12.7, 6.7, 139.8, 116.4, 50.7, 49.2],
                      backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    }
                  ]
                },
                options: {
                  responsive: true,
                  lineTension: 1,
                  scales: {
                      yAxes: [{
                          stacked: false
                      }],

                        }
                }
              },

      search: '',
      headers: [
        { title: 'Datum',
            fixed: true,
            key: 'aktivitaeten_startTimeGMT',align: 'end'},
        {
          title: 'Strecke',
          align: 'start',
          key: 'aktivitaeten_distance',
        },
        { title: 'Ø km/h',key: 'aktivitaeten_averageSpeed',align: 'end'},
        { title: 'Ø (move) km/h',key: 'aktivitaeten_movingaverageSpeed',align: 'end'},

        { title: 'activity Name',key: 'aktivitaeten_activityName',align: 'end'},

        { title: 'Dauer hh:mm',
            key: 'aktivitaeten_duration',
            align: 'end'},
        { title: 'Ø HR', key: 'aktivitaeten_averageHR',align: 'end'},
        { title: 'Ø RPM', key: 'aktivitaeten_averageBikingCadenceInRevPerMinute',align: 'end'},
        { title: 'Ø PWR', key: 'aktivitaeten_avgPower',align: 'end'},
        { title: 'maxPWR', key: 'aktivitaeten_maxPower',align: 'end'},
        { title: 'maxPWR (20 Min)', key: 'aktivitaeten_max20MinPower',align: 'end'},
        { title: 'normalized Power', key: 'aktivitaeten_normPower',align: 'end'},
        { title: 'Intensity Factor', key: 'aktivitaeten_intensityFactor',align: 'end'},
        { title: 'Training Stress Score', key: 'aktivitaeten_trainingStressScore',align: 'end'},
        { title: 'vO2Max', key: 'aktivitaeten_vO2MaxValue',align: 'end'},
        { title: 'FTP', key: 'aktivitaeten_maxFtp',align: 'end'},
        { title: 'aerob Auswirkung', key: 'aktivitaeten_aerobicTrainingEffect',align: 'end'},
        { title: 'anaerob Auswirkung', key: 'aktivitaeten_anaerobicTrainingEffect',align: 'end'},
        { title: 'minTemperature', key: 'aktivitaeten_minTemperature',align: 'end'},
        { title: 'activityId', key: 'aktivitaten_activityId',align: 'end'},
        { title: 'activityType', key: 'aktivitaeten_activityType',align: 'end'},
        { title: 'aktivitaeten max Elevation', key: 'aktivitaeten_maxElevation',align: 'end'},
        { title: 'aktivitaeten min Elevation', key: 'aktivitaeten_minElevation',align: 'end'},
        { title: 'maxSpeed',
            key: 'aktivitaeten_maxSpeed',
            align: 'end'},
        { title: 'Kalorien', key: 'aktivitaeten_calories',align: 'start'},
        { title: 'Positiver Höhen unterschied', key: 'aktivitaten_elevationGain',align: 'end'},

      ],
        pagination: {
          rowsPerPage: -1
        },
        latestGoogleupdate :'',
        pagination_aggregates : {'sortBy': 'stock_username', 'descending': true, 'rowsPerPage': -1},
        headers_Aggregates: [
          {
            text: 'stock_username ',
            align: 'left',
            sortable: true,
            value: 'stock_username',
            filter: value => {
              console.log('Filtervalues:',this.Users)
              console.log('UsersCheckBoxStatus:',this.UsersCheckBoxStatus)
              //console.log('Zeilenvalue:',value)


              var trefferFlag = false

              this.UsersCheckBoxStatus.forEach(function(item){
                    //console.log('item',item)
                    if (item == value){
                      //console.log('Treffer')
                      trefferFlag = true
                    }
                  //console.log('item',item);
                })
              //return true

              return trefferFlag



            },
          },
          {
            text: 'stocks_name',
            align: 'left',
            sortable: true,
            value: 'stocks_name'
          },
          { text: 'Delta gesamt €', value: 'stock_Aktuellerkurs',align: 'right'},
          { text: 'Delta T %', value: 'stock_delta1_prozent',align: 'right'},
          { text: 'Delta W %', value: 'stock_delta5_prozent',align: 'right'},
          { text: 'Delta M %', value: 'stock_delta20_prozent',align: 'right'},


        ],
    };
  },
  created: function() {
    console.log('Bin in ActivitiesScreen')

  },
  mounted: function(){

    console.log('>>Bin in mounted:',this.attribute)

    var obj = this

    //var setKategorie = false
    this.e7=[]

      this.$store.dispatch('getSettings').then(response => {
        console.log("Got some data, now lets show something in this component",response)

//              for (let i = 0; i < obj.settings.length; i++) {
//                console.log('Settings',this.settings[i])
//              }


              var setKategorie = false

                //selektierte Kategorien von DB lesen
                obj.attribute.forEach(function(element){
                    //console.log('ele:',element.Kategorie)
                    setKategorie = obj.settings.find(x => x.settings_key === element.Kategorie)
                    //setKategorie = getSettingsByKey(element.Kategorie)
                    //console.log('>>SET Attribute Kategorie:',setKategorie)
                    if(setKategorie.settings_value == 1){
                        var setNeu = {
                           "Kategorie":element.Kategorie,
                           "dbname": element.dbname,
                           }
                        obj.e7.push(setNeu)
                        }
                  });


    }, error => {
        console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
    })

    obj.createbar2Chart("bar2-chart", obj.bar2ChartData);
    obj.createbar3HRChart("bar3HR-chart", this.bar3HRChartData);
    obj.createline2Chart("line2-chart", this.line2ChartData);
    obj.createline4TSSChart("line4TSS-chart", this.line4TSSChartData);


    var row

    obj.bar2ChartData.data.datasets[0].data = []
    obj.bar2ChartData.data.datasets[1].data = []
    //console.log('myLabels vorher:',obj.barChartData.data.labels)
    obj.bar2ChartData.data.labels = []


      this.$store.dispatch('getActivities').then(()=>{
        console.log("--Got some activiteis data--")
        obj.activities.forEach(function(element) {
            //console.log('#',element.aktivitaeten_startTimeGMT)

            element.aktivitaeten_movingaverageSpeed = Math.round(element.aktivitaeten_distance / element.aktivitaeten_movingDuration * 3.6 * 100) /100
            //row = JSON.stringify(element)
            row = element
            row.aktivitaeten_startTimeGMT = new Date(element.aktivitaeten_startTimeGMT)
            //console.log('#row',row)
            obj.array_allActivitiesJSON.push(row)
            //console.log('#array_allActivitiesJSON',obj.array_allActivitiesJSON)

            //console.log('ele:',element)
            //obj.array_allActivities.push(row) //array fuer Tabelle aufbereite

            //Daten fuer Bar-Chart aufbereiten

            obj.lineChartData.data.labels.push(element.aktivitaeten_startTimeGMT)
            obj.lineChartData.data.datasets[0].data.push(Math.ceil(element.aktivitaeten_averageSpeed * 3.6 *100)/100)
            obj.lineChartData.data.datasets[1].data.push(element.aktivitaeten_averageHR)

        })

        //einmal umsortieren
        obj.array_allActivities = obj.array_allActivitiesJSON.slice();
        obj.array_allActivitiesJSON = _.reverse( obj.array_allActivitiesJSON )


        obj.filterDates(obj.Zeitraum)

        return
        })


  },
  computed: {
   ...mapGetters({ activities: 'stateActivities',settings: 'stateSettings'}),
  },
  methods: {
    ...mapActions(['createNote']),
    async submit() {
      console.log('DashboardScreen: submit createNote')
      await this.createNote(this.form);
    },

    selection_changedValues (values) {
      console.log('##########################')
      console.log('newselection2:',values)

         this.attribute.map((e,i)=>{
          //let temp = values.find(element=> element.Kategorie === e.Kategorie)
          var test = function (element) {
               console.log('tuti Treffis',element,i)
                  if(element.Kategorie === e.Kategorie) {
                    return true

                  }

            };

          var result = values.find(test)
          var obj = this

            var setNeu = {
            "c_group": "0",
            "c_owner": "0",
            "c_unixperms": "0",
              "settings_key": e.Kategorie,
              "settings_value": "1",
            "user_iduser": "0",
            }

          if(result){
            //console.log('Selektiert und damit true:',e.Kategorie)

              // console.log('setNeu True:',setNeu)
            //this.$store.dispatch('CHANGE_SETTING',setNeu)
                    obj.$store.dispatch('createSetting',setNeu).then(()=>{
                        //console.log("Got some data, now lets show something in this component")


                      }, error => {
                        console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
                      })
          }
          else{
            //console.log('NICHT Selektiert und damit false',e.Kategorie)
            setNeu = {
            "c_group": "0",
            "c_owner": "0",
            "c_unixperms": "0",
              "settings_key": e.Kategorie,
              "settings_value": "0",
            "user_iduser": "0",
            }
               //console.log('setNeu False:',setNeu)
            //this.$store.dispatch('CHANGE_SETTING',setNeu)
            //this.$store.dispatch('CHANGE_SETTING',setNeu)
                    obj.$store.dispatch('createSetting',setNeu).then(()=>{
                        //console.log("Got some data, now lets show something in this component")



                      }, error => {
                        console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
                      })
          }

        })









      this.filterDates(this.zeitraum)

      },
      createDurchschnittsChartHR (orderedByZeitraum) {
      var obj = this
      console.log('newselection HR:',orderedByZeitraum)
          //aufbereitung fuer alle unabhaengig von zeitraum
              //flush array
              //obj.barChartData.data.labels = []
              for (var i = obj.bar3HRChart.data.labels.length; i >= 0; i--) {
                 //obj.barChartData.data.labels.pop();
                 obj.bar3HRChart.data.labels.pop();
                }
              for (i = obj.bar3HRChart.data.datasets.length; i >= 0; i--) {
                 //obj.barChartData.data.datasets.pop();
                 obj.bar3HRChart.data.datasets.pop();
                }
              //console.log('Anzahl selektierte Kategorien:',this.e7.length)

          //array allokieren
          var farben =['rgba(192, 192, 192, 0.8)','rgba(0, 0, 255, 0.8)','rgba(0, 255, 0, 0.8)','rgba(245, 171, 53, 0.8)','rgba(255, 0, 0, 0.8)']
          var index3 = 0
          for (i = this.e9_HR.length; i > 0; i--){ //neuen Array allokieren
              //console.log('pump array to length:',this.e9_HR.length)

              var neuesDataset = {}
              neuesDataset['label'] = 'newLabel'
              neuesDataset['data'] = []
              neuesDataset['backgroundColor'] = farben[index3]

              //obj.barChartData.data.datasets.push(neuesDataset)
              obj.bar3HRChart.data.datasets.push(neuesDataset)
              index3 +=1
            }

              //obj.barChartData.data.datasets[0].data = []
              //obj.barChartData.data.datasets[1].data = []
              //drueber loopen und summieren
              var index = 0
              _.forEach(orderedByZeitraum,item2 => {
                    //console.log('item2',Object.keys(orderedByZeitraum)[index],item2)

                            var index2 = 0
                            for (var key in this.e9_HR) {
                                var value = this.e9_HR[key];
                                //console.log('>>>Add to chart selected kategore:',value.dbname)


                                //var CaloriesProZeitraum = _.sumBy(item2, 'aktivitaeten_calories');
                                var aktivitaeten_secsProZeitraum = _.sumBy(item2, value.dbname);
                                //if(value.dbname==='aktivitaeten_Zone1_secs'){
                                //    aktivitaeten_Zone1_secsProZeitraum = CaloriesProZeitraum / 1000 //Umrechnenin km
                                //}
                                //<span v-text="Math.floor(item.aktivitaeten_duration / 60) + ':' + ('0' + Math.floor(item.aktivitaeten_duration % 60)).slice(-2) + ' Min'"></span>
                                if(value.dbname==='aktivitaeten_Zone1_secs'){
                                    //console.log('>>>aktivitaeten_duration:',CaloriesProZeitraum)
                                    aktivitaeten_secsProZeitraum = aktivitaeten_secsProZeitraum / 60   //Umrechnen in Minuten
                                    //console.log('>>Zone1 in Minuten:',aktivitaeten_secsProZeitraum)

                                }
                                if(value.dbname==='aktivitaeten_Zone2_secs'){
                                    //console.log('>>>aktivitaeten_duration:',CaloriesProZeitraum)
                                    aktivitaeten_secsProZeitraum = aktivitaeten_secsProZeitraum / 60  //Umrechnen in Minuten
                                }

                                if(value.dbname==='aktivitaeten_Zone3_secs'){
                                    //console.log('>>>aktivitaeten_duration:',CaloriesProZeitraum)
                                    aktivitaeten_secsProZeitraum = aktivitaeten_secsProZeitraum / 60  //Umrechnen in Minuten
                                }
                                if(value.dbname==='aktivitaeten_Zone4_secs'){
                                    //console.log('>>>aktivitaeten_duration:',CaloriesProZeitraum)
                                    aktivitaeten_secsProZeitraum = aktivitaeten_secsProZeitraum / 60  //Umrechnen in Minuten
                                }
                                if(value.dbname==='aktivitaeten_Zone5_secs'){
                                    //console.log('>>>aktivitaeten_duration:',CaloriesProZeitraum)
                                    aktivitaeten_secsProZeitraum = aktivitaeten_secsProZeitraum / 60  //Umrechnen in Minuten
                                }
                                //getkeyName mit Index und dann die Anzahl ermitteln
                                var keyname = Object.keys(orderedByZeitraum)[index]
                                var DurchschnittProZeitraum = aktivitaeten_secsProZeitraum / orderedByZeitraum[keyname].length

                                obj.bar3HRChart.data.datasets[index2].data.push(DurchschnittProZeitraum)
                                //obj.barChartData.data.datasets[index2].label = value.dbname
                                var chartLabel = value.dbname.split('_')[1];
                                obj.bar3HRChart.data.datasets[index2].label = chartLabel
                                //obj.barChartData.data.datasets[1].data.push(666)
                                //console.log('>>>Summe pro kategore:',value.dbname, CaloriesProZeitraum)
                                index2 += 1

                            }
                            //obj.barChartData.data.labels.push(Object.keys(orderedByZeitraum)[index])
                            obj.bar3HRChart.data.labels.push(Object.keys(orderedByZeitraum)[index])



                    index +=1
                })
          obj.createbar3HRChart('bar3HR-chart', obj.bar3HRChartData)




      },
    createDurchschnittsChart (orderedByZeitraum) {
      var obj = this
      //console.log('createDurchschnittchartsChart:',orderedByZeitraum)
              //flush array
              //obj.barChartData.data.labels = []
              for (var i = obj.line2Chart.data.labels.length; i > 0; i--) {
                 obj.line2Chart.data.labels.pop();
                }
              for (i = obj.line2Chart.data.datasets.length; i > 0; i--) {
                 obj.line2Chart.data.datasets.pop();
                }
              //console.log('Anzahl selektierte DURCHSCNHITTS Kategorien:',this.e8_Durchschnitt.length)

          //array allokieren
          var farben =['rgba(244, 208, 63, 0.2)','rgba(135, 54, 0, 0.2)','rgba(50, 192, 50, 0.2)','rgba(75, 192, 192, 0.2)']
          var yAxisID =['yaxisLinks','yaxisRechts']
          var index3 = 0
          for (i = this.e8_Durchschnitt.length; i > 0; i--){ //neuen Array allokieren
              //console.log('pump array to length:',this.e8_Durchschnitt.length)

              var neuesDataset = {}
              neuesDataset['label'] = 'newLabel'
              neuesDataset['data'] = []
              neuesDataset['backgroundColor'] = farben[index3]
              neuesDataset['yAxisID'] = yAxisID[index3]
              obj.line2Chart.data.datasets.push(neuesDataset)
              index3 +=1
            }

              //obj.barChartData.data.datasets[0].data = []
              //obj.barChartData.data.datasets[1].data = []
              //drueber loopen und summieren
              var index = 0
                // Initialize variables for sum and count
              _.forEach(orderedByZeitraum,item2 => {
                    //console.log('item2',Object.keys(orderedByZeitraum)[index],item2)

                            var index2 = 0
                            for (var key in this.e8_Durchschnitt) {
                                var value = this.e8_Durchschnitt[key];
                                //console.log('>>>Add to chart selected kategore:',value.dbname)


                                //var CaloriesProZeitraum = _.sumBy(item2, 'aktivitaeten_calories');
                                if (value.dbname!= 'aktivitaeten_averageSpeed'){
                                    var SummeProZeitraum = _.sumBy(item2, value.dbname);
                                    }

                                //Dia Anzahl de Teilers muss ich ueber den -

                                //getkeyName mit Index und dann die Anzahl ermitteln
                                var keyname = Object.keys(orderedByZeitraum)[index]

                                //var DurchschnittProZeitraum = SummeProZeitraum / Object.keys(orderedByZeitraum).length //stimmt noch nicht ich muss die Anzahl pro Wochen ermitteln und durch diese teilen
                                if (value.dbname!= 'aktivitaeten_averageSpeed'){
                                    var DurchschnittProZeitraum = SummeProZeitraum / orderedByZeitraum[keyname].length //stimmt noch nicht ich muss die Anzahl pro Wochen ermitteln und durch diese teilen
                                    }
                                else{
                                 //DurchschnittProZeitraum = SummeProZeitraum / AnzahlRadActivities
                                // Filter speeds by "indoor_cycling" and calculate the average
                                //console.log('keyname:',keyname)
                                const CyclingProZeitraum = orderedByZeitraum[keyname]
                                    //.filter(activity => activity.aktivitaeten_activityType === "indoor_cycling")
                                      .filter(activity =>
                                        activity.aktivitaeten_activityType === "indoor_cycling" ||
                                        activity.aktivitaeten_activityType === "mountain_biking" ||
                                        activity.aktivitaeten_activityType === "cycling" ||
                                        activity.aktivitaeten_activityType === "road_biking"
                                      )
                                    .map(activity => activity.aktivitaeten_averageSpeed);

                                DurchschnittProZeitraum = CyclingProZeitraum.reduce((sum, speed) => sum + speed, 0) / CyclingProZeitraum.length;
                                DurchschnittProZeitraum = DurchschnittProZeitraum *3.6 //Umrechnenin km/h
                                }

                                //console.log('CaloriesProZeitraum',CaloriesProZeitraum)
                                //chart Daten aufbereiten
                                obj.line2Chart.data.datasets[index2].data.push(DurchschnittProZeitraum)
                                var linechartLabel = value.dbname.split('_')[1];
                                obj.line2Chart.data.datasets[index2].label = linechartLabel + ' / Zeiteinheit'
                                //obj.barChartData.data.datasets[1].data.push(666)
                                //console.log('>>>Durchschnitt pro kategore:',value.dbname,SummeProZeitraum, orderedByZeitraum[keyname].length, DurchschnittProZeitraum)
                                index2 += 1

                            }
                            obj.line2Chart.data.labels.push(Object.keys(orderedByZeitraum)[index])
                    index +=1
                })

           obj.line2ChartData.options.scales.yaxisRechts = { type: 'linear', position: 'right' }
           //console.log('####scales##',obj.line2ChartData.options.scales)
           //console.log('####options##',obj.line2ChartData.options)
          obj.createline2Chart('line2-chart', obj.line2ChartData)


      },
    createDurchschnittsChartTSS (orderedByZeitraum) {
      var obj = this
      console.log('createDurchschnittsChartTSS:',orderedByZeitraum)

              //flush array
              //obj.barChartData.data.labels = []
              while (this.line4TSSChartData.data.labels.length > 0) {
                  this.line4TSSChartData.data.labels.pop();
                }
//              for (var i = this.line4TSSChartData.data.labels.length; i > 0; i--) {
//                console.log('label:',obj.line4TSSChart.data.labels[i])
//                 obj.line4TSSChart.data.labels.pop();
//                }
//              i = 0
              while (this.line4TSSChartData.data.datasets.length > 0) {
                  this.line4TSSChartData.data.datasets.pop();
                }
//              for (var i = obj.line4TSSChartData.data.datasets.length; i > 0; i--) {
//                 obj.line4TSSChart.data.datasets.pop();
//                }
              //console.log('Anzahl selektierte DURCHSCNHITTS Kategorien:',this.e8_Durchschnitt.length)

          //array allokieren
          var farben =['rgba(255, 153, 80, 0.2)','rgba(128, 77, 0, 0.8)','rgba(50, 192, 50, 0.2)','rgba(75, 192, 192, 0.2)']
          var yAxisID =['yaxisLinks','yaxisRechts']
          var index3 = 0
          for (var i = this.e10_TSS_Durchschnitt.length; i > 0; i--){ //neuen Array allokieren
              //console.log('pump array to length:',this.e8_Durchschnitt.length)

              var neuesDataset = {}
              neuesDataset['label'] = 'newLabel'
              neuesDataset['data'] = []
              neuesDataset['backgroundColor'] = farben[index3]
              neuesDataset['yAxisID'] = yAxisID[index3]
              neuesDataset['fill'] = 'false'
              neuesDataset['pointStyle'] = 'rect'
              neuesDataset['borderColor'] = 'rgba(128, 77, 0, 0.7)'

              obj.line4TSSChart.data.datasets.push(neuesDataset)
              index3 +=1
            }

              //obj.barChartData.data.datasets[0].data = []
              //obj.barChartData.data.datasets[1].data = []
              //drueber loopen und summieren
              var index = 0

              _.forEach(orderedByZeitraum,item2 => {
                    //console.log('item2',Object.keys(orderedByZeitraum)[index],item2)
                    var AnzahlFTPungleichNull = 0
                            var index2 = 0
                            for (var key in this.e10_TSS_Durchschnitt) {
                                var value = this.e10_TSS_Durchschnitt[key];
                                //console.log('>>>Add to chart selected kategore:',value.dbname)


                                //was mache ich wenn alle Null sind, dann mus ich mir den vorherigen ruaslesen
                                var keyname = Object.keys(orderedByZeitraum)[index]
                                var SummeProZeitraum = _.sumBy(item2, value.dbname);
                                var DurchschnittProZeitraum = SummeProZeitraum / orderedByZeitraum[keyname].length //stimmt noch nicht ich muss die Anzahl pro Wochen ermitteln und durch diese teilen

                                //wie ermittle ich die Anzahl der FTP Null

                                if(value.dbname==='aktivitaeten_maxFtp'){
                                    _.sumBy(item2, element => {
                                        //console.log('>>>>>>>FTP element Value:',element.aktivitaeten_maxFtp)
                                        if(element.aktivitaeten_maxFtp)
                                            { AnzahlFTPungleichNull +=1}
                                        //if(element.)
                                        //return car.cost > 39000 ? car.cost : 0;
                                    });
                                    //console.log('>>>>>>>FTP elements in diesem Segment:',AnzahlFTPungleichNull)
                                    if(AnzahlFTPungleichNull)
                                        {
                                          DurchschnittProZeitraum = SummeProZeitraum / AnzahlFTPungleichNull
                                        }
                                    obj.line4TSSChart.data.datasets[index2].type = 'line'
                                    obj.line4TSSChart.data.datasets[index2].lineTension = 0.5
                                }

                                //chart Daten aufbereiten
                                obj.line4TSSChart.data.datasets[index2].data.push(DurchschnittProZeitraum)
                                var linechartLabel = value.dbname.split('_')[1];
                                obj.line4TSSChart.data.datasets[index2].label = linechartLabel + ' / Zeiteinheit'
                                //obj.barChartData.data.datasets[1].data.push(666)
                                //console.log('>>>Durchschnitt pro kategore:',value.dbname,SummeProZeitraum, orderedByZeitraum[keyname].length, DurchschnittProZeitraum)
                                index2 += 1

                            }
                            obj.line4TSSChart.data.labels.push(Object.keys(orderedByZeitraum)[index])
                    index +=1
                })
           obj.line4TSSChartData.options.scales.yaxisRechts = { type: 'linear', position: 'right' }
           //console.log('####scales##',obj.line4TSSChartData.options.scales)
          obj.createline4TSSChart('line4TSS-chart', obj.line4TSSChartData)


      },
    createSummenChart (orderedByZeitraum) {
      var obj = this
      //console.log('newselection:',orderedByZeitraum)
          //aufbereitung fuer alle unabhaengig von zeitraum
              //flush array
              //obj.barChartData.data.labels = []
              for (var i = obj.bar2ChartData.data.labels.length; i >= 0; i--) {
                 //obj.barChartData.data.labels.pop();
                 obj.bar2ChartData.data.labels.pop();
                }
              for (i = obj.bar2ChartData.data.datasets.length; i >= 0; i--) {
                 //obj.barChartData.data.datasets.pop();
                 obj.bar2ChartData.data.datasets.pop();
                }
              //console.log('Anzahl selektierte Kategorien:',this.e7.length)

          //array allokieren
          var farben =['rgba(75, 192, 192, 0.2)','rgba(205, 92, 92, 0.2)','rgba(50, 192, 50, 0.2)','rgba(75, 192, 192, 0.2)']
          var index3 = 0
          for (i = this.e7.length; i > 0; i--){ //neuen Array allokieren
              //console.log('pump array to length:',this.e7.length)

              var neuesDataset = {}
              neuesDataset['label'] = 'newLabel'
              neuesDataset['data'] = []
              neuesDataset['backgroundColor'] = farben[index3]

              //obj.barChartData.data.datasets.push(neuesDataset)
              obj.bar2ChartData.data.datasets.push(neuesDataset)
              index3 +=1
            }

              //obj.barChartData.data.datasets[0].data = []
              //obj.barChartData.data.datasets[1].data = []
              //drueber loopen und summieren
              var index = 0
              console.log('>>>e7:',this.e7)
              _.forEach(orderedByZeitraum,item2 => {
                    console.log('item2',Object.keys(orderedByZeitraum)[index],item2)
                    console.log('>>>>>>item2.length:',item2.length)
                    //obj.bar2ChartData.data.datasets[index2].data.push(item2.length)

                            var index2 = 0
                            for (var key in this.e7) {
                                var value = this.e7[key];
                                console.log('>>>Add to chart selected kategore:',value.dbname)


                                var SummeProZeitraum = _.sumBy(item2, value.dbname);
                                if(value.dbname==='aktivitaeten_distance'){
                                    SummeProZeitraum = SummeProZeitraum / 1000 //Umrechnenin km
                                }



                                var chartLabel = value.dbname.split('_')[1];
                                obj.bar2ChartData.data.datasets[index2].lineTension = 0.5

                                obj.bar2ChartData.data.datasets[index2].label = chartLabel

                                if(value.dbname==='aktivitaeten_AnzahlAktivitaeten'){
                                        obj.bar2ChartData.data.datasets[index2].data.push(item2.length)

                                }

                                if(value.dbname==='aktivitaeten_calories'){
                                        obj.bar2ChartData.data.datasets[index2].data.push(SummeProZeitraum)

                                }

                                if(value.dbname==='aktivitaeten_distance'){
                                        obj.bar2ChartData.data.datasets[index2].data.push(SummeProZeitraum)

                                }

                                if(value.dbname==='aktivitaten_elevationGain'){
                                        obj.bar2ChartData.data.datasets[index2].data.push(SummeProZeitraum)

                                }

                                if(value.dbname==='aktivitaeten_duration'){
                                        SummeProZeitraum = SummeProZeitraum / 60 /60  //Umrechnen in Stunden
                                        obj.bar2ChartData.data.datasets[index2].data.push(SummeProZeitraum)

                                }

                                var indoorDistance = _.sumBy(item2, function(element)
                                        {
                                            if(element.aktivitaeten_activityType == 'indoor_cycling')
                                            {
                                                var idist = element.aktivitaeten_distance / 1000
                                                //console.log('<<<<<<INdoor Aktivitatet mit Distance:',element.aktivitaten_activityId, idist)
                                                return idist;
                                            }
                                        });

                                if(value.dbname==='aktivitaeten_distanceIndoor'){
                                    //console.log('>>>Dataset:',obj.bar2ChartData.data)
                                    //index2 += 1
                                    //zweite neue Saeule fuer indoor distanz
                                    if (obj.bar2ChartData.data.datasets.length>1){
                                        obj.bar2ChartData.data.datasets[1].data.push(indoorDistance)
                                        chartLabel = value.dbname.split('_')[1];
                                        //obj.bar2Chart.data.datasets[1].label = chartLabel
                                        obj.bar2ChartData.data.datasets[1].type = 'line'

                                        index2 -= 1
                                        }
                                    else{
                                        obj.bar2ChartData.data.datasets[0].data.push(indoorDistance)
                                        chartLabel = value.dbname.split('_')[1];
                                        //obj.bar2ChartData.data.datasets[0].label = chartLabel
                                        obj.bar2ChartData.data.datasets[0].type = 'line'


                                    }
                                }

                                //console.log('>>>Summe pro kategore:',value.dbname, CaloriesProZeitraum)
                                index2 += 1

                            }
                            //obj.barChartData.data.labels.push(Object.keys(orderedByZeitraum)[index])
                            obj.bar2ChartData.data.labels.push(Object.keys(orderedByZeitraum)[index])



                    index +=1
                })

         //Zu Testzwecken ausgaben in einem Format das direkt in Javascript verarbeitet werden kann
         //var chartAsString = JSON.stringify(obj.bar2Chart.data)
         //const unquoted = chartAsString.replace(/"([^"]+)":/g, '$1:');
         //console.log(chartAsString);
         //console.log(unquoted);


          //obj.createChart('bar-chart', obj.barChartData)
          //obj.bar2Chart.update()
          obj.createbar2Chart("bar2-chart", obj.bar2ChartData);



      },
    filterDates (Zeitraum) {

        this.zeitraum = Zeitraum

        console.log('filterDates:',Zeitraum)
//        for (var key in this.e7) {
//            var value = this.e7[key];
//            console.log('selected kategore:',value)
//
//        }


        //console.log('array_allActivities:',this.array_allActivitiesJSON)

        //var json = _.reverse( this.array_allActivitiesJSON )
        var json = this.array_allActivitiesJSON
        //var obj = this

          //var groupedByActivityName = _.groupBy(json,'aktivitaeten_activityName')
          //console.log('groupedByActivityName:',groupedByActivityName)

//            const monthNames = ["January", "February", "March", "April", "May", "June",
//              "July", "August", "September", "October", "November", "December"
//            ];

            var orderedByZeitraum =[]

            if(this.zeitraum === 'Jahr'){
              orderedByZeitraum = _.groupBy(json, function(element) {
                              //return element.aktivitaeten_startTimeGMT.substring(0,7);
                              //console.log('elem:',element.aktivitaeten_startTimeGMT)
                              //return monthNames[element.aktivitaeten_startTimeGMT.getMonth()]
                              return element.aktivitaeten_startTimeGMT.getFullYear()
                          });
              console.log('groupedByYear:',orderedByZeitraum)


            }else if (this.zeitraum === 'Woche'){
                console.log('groupedBy:',Zeitraum)
                Date.prototype.getWeek = function() {
                    var dt = new Date(this.getFullYear(),0,1);
                    var kalenderwoche = Math.ceil((((this - dt) / 86400000) + dt.getDay()+1)/7)
                    var JahrUndWoche = (dt.getFullYear()-2000).toString() +'_' + kalenderwoche
                    //console.log('kw:',JahrUndWoche +'_' + kalenderwoche)
                    //return Math.ceil((((this - dt) / 86400000) + dt.getDay()+1)/7);
                    return JahrUndWoche;
                };
                  orderedByZeitraum = _.groupBy(json, function(element) {
                  //return element.aktivitaeten_startTimeGMT.substring(0,7);
                  //console.log('elem:',element.aktivitaeten_startTimeGMT)
                  //return monthNames[element.aktivitaeten_startTimeGMT.getMonth()]
                  return element.aktivitaeten_startTimeGMT.getWeek()
                  });
                console.log('groupedByWeek:',orderedByZeitraum)

            }
            else{
                 Date.prototype.getYearMonth = function() {
                      var JahrUndMonat = this.getFullYear().toString() +'_' + (this.getMonth()+1).toString()
                      //console.log('JahrUndMonat:',JahrUndMonat)
                      return JahrUndMonat;
                 };
                  orderedByZeitraum = _.groupBy(json, function(element) {
                    //console.log('elem:',element.aktivitaeten_startTimeGMT)
                    return element.aktivitaeten_startTimeGMT.getYearMonth()
                    });
                  //console.log('groupedByYearMonth:',orderedByZeitraum)

//              orderedByZeitraum = _.groupBy(json, function(element) {
//                              //return element.aktivitaeten_startTimeGMT.substring(0,7);
//                              //console.log('elem:',element.aktivitaeten_startTimeGMT)
//                              return monthNames[element.aktivitaeten_startTimeGMT.getMonth()]
//                          });
//              console.log('groupedByMonth:',orderedByZeitraum)

          }//Sum/Monat

          var AnzahlSaetze = _.size(orderedByZeitraum)//Object.keys(orderedByZeitraum).length
          //var Offset = 20
          //var counter = 0
          var orderedByZeitraumSliced = {}
          var index = 0

          //die letzetn 10 anzeigen
          if (AnzahlSaetze > this.offset){
              _.forEach(orderedByZeitraum,item2 => {
                //console.log('####',Object.keys(orderedByZeitraum)[index])
                //console.log('#---',item2)

                if(index >= AnzahlSaetze - this.offset){

                 //orderedByZeitraumSliced.push(pan)  //nicht push sondern als key hinzufuegen
                 orderedByZeitraumSliced[Object.keys(orderedByZeitraum)[index]] = item2;
                 }
                index +=1
               })

              //console.log('>>sliced:',orderedByZeitraumSliced)
              this.createSummenChart(orderedByZeitraumSliced)
              this.createDurchschnittsChart(orderedByZeitraumSliced)
              this.createDurchschnittsChartHR(orderedByZeitraumSliced)
              this.createDurchschnittsChartTSS(orderedByZeitraumSliced)
          }
          else{
          //ab hier chart spezifisch
          this.createSummenChart(orderedByZeitraum)
          this.createDurchschnittsChart(orderedByZeitraum)
          this.createDurchschnittsChartHR(orderedByZeitraum)
          this.createDurchschnittsChartTSS(orderedByZeitraum)
          }


      },
    createline4TSSChart(chartId, chartData) {

        let chartStatus = Chart.getChart(chartId); // <canvas> id
        if (chartStatus != undefined) {
            chartStatus.destroy();
            }

      const ctx = document.getElementById(chartId);
      // Save reference
      this.line4TSSChart = new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options
      });
    },

    createbar3HRChart(chartId, chartData) {

        let chartStatus = Chart.getChart(chartId); // <canvas> id
        if (chartStatus != undefined) {
            chartStatus.destroy();
            }

      const ctx = document.getElementById(chartId);
      // Save reference
      this.bar3HRChart = new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options
      });
    },
    createline2Chart(chartId, chartData) {

        let chartStatus = Chart.getChart(chartId); // <canvas> id
        if (chartStatus != undefined) {
            chartStatus.destroy();
            }


      const ctx = document.getElementById(chartId);
      // Save reference
      this.line2Chart = new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options
      });
    },

    createbar2Chart(canvasID, chartData) {

    // JS - Destroy exiting Chart Instance to reuse <canvas> element
    let chartStatus = Chart.getChart(canvasID); // <canvas> id
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }
    //-- End of chart destroy

      const ctx = document.getElementById(canvasID);
      // Save reference
      new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options
      });

    },
      getUser (index) {
          //console.log('getUser:',index)
          //console.log('Users:',Object.keys(this.Users),Object.keys(this.Users)[index])
          return Object.keys(this.Users)[index]

      },
    handleClickActivty (item,row) {
        console.log('###handleClickActivty:',row.item.aktivitaeten_distance,item)
        console.log('make map with this tracserial :',row.item.aktivitaten_activityId)

        this.$router.push({name: 'Tracks', params: {aktivitaten_activityId: row.item.aktivitaten_activityId}})
    },
    filterAktuellerKurs (value, query, item) {
        //console.log('filter:',value, query, item.columns.stock_Aktuellerkurs)
        console.log('UsersCheckBoxStatus:',this.UsersCheckBoxStatus)
        var queryAlsFloat = parseFloat(query)
        //console.log('aha >:',stock_AktuellerkursAlsFloat,queryAlsFloat,this.SchwellwertAktuellerKurs)
        if (queryAlsFloat<item.columns.stock_Aktuellerkurs_gesamt) return true
        else return false
    },

  },
};
</script>